//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ForgotPassword',
  props: {
    forgotPasswordConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'user_forgot' }),
      state: {
        time: 600,
        smsSendBtn: false
      },
      registerBtn: false,
      sent: false
    };
  },
  computed: {
    options() {
      const defaults = {};
      return Object.assign(defaults, this.forgotPasswordConfig || {});
    }
  },
  methods: {
    forgotPasswordSubmit() {
      const {
        form: { validateFields }
      } = this;
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          const { email, code, password } = values;
          this.$s.api.user
            .forgotPasswordSubmit(email, code, password)
            .then(() => {
              this.$message.success(this.$tc('Change password has been successful'), 2, () => {
                this.$router.push({ path: '/user/login', query: { ...this.$route.query } });
              });
            })
            .catch(e => {
              this.$notification.error({
                message: this.$t('Error'),
                description: this.$t('Confirmation code is wrong. Please try again.'),
                duration: 8
              });
              this.setError(e);
            });
        }
      });
    },

    setError(err) {
      this.$emit('onError', err);
      this.registerBtn = false;
    },
    forgotPassword() {
      const {
        form: { validateFields },
        state,
        $message
      } = this;

      validateFields(['email'], { force: true }, async (err, values) => {
        if (!err) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 600;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);

          console.log(values);

          const hide = $message.loading(this.$tc('Reset code is being sent...'), 0);
          this.$s.api.user
            .forgotPassword(values.email)
            .then(() => {
              this.sent = true;
              this.$notification.success({
                message: this.$t('Notice'),
                description: this.$t(
                  'A verification code for resetting has been sent. Please check your email.'
                ),
                duration: 8
              });
            })
            .catch(err => {
              clearInterval(interval);
              state.time = 600;
              state.smsSendBtn = false;
              this.setError(err);
              this.$notification.error({
                message: this.$t('Error'),
                description: this.$t('Confirmation code is wrong. Please try again.'),
                duration: 8
              });
            })
            .finally(() => {
              setTimeout(hide, 1);
            });
        }
      });
    },
    signIn() {
      this.$router.push({ path: 'login', query: { ...this.$route.query } });
    }
  }
};
