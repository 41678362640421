var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main user-layout-reset" },
    [
      _c("div", { staticClass: "text-h6 q-my-md" }, [
        _vm._v(" " + _vm._s(_vm.$t("Reset your password")) + " ")
      ]),
      _c(
        "a-form",
        { attrs: { autocomplete: "off", form: _vm.form } },
        [
          _c(
            "a-form-item",
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "email",
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("Please input your E-mail!")
                          },
                          {
                            type: "email",
                            message: _vm.$t("The input is not valid E-mail!")
                          }
                        ],
                        validateTrigger: ["change", "blur"]
                      }
                    ],
                    expression:
                      "[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('Please input your E-mail!')\n              },\n              {\n                type: 'email',\n                message: $t('The input is not valid E-mail!')\n              }\n            ],\n            validateTrigger: ['change', 'blur']\n          }\n        ]"
                  }
                ],
                attrs: {
                  type: "text",
                  autocomplete: "new-username",
                  placeholder: _vm.$t("Enter your email")
                }
              })
            ],
            1
          ),
          _vm.sent
            ? _c(
                "a-row",
                { attrs: { type: "flex", justify: "center", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { staticClass: "gutter-row", attrs: { span: 16 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-input",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "code",
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: _vm.$t(
                                            "Please input verification code"
                                          )
                                        }
                                      ],
                                      validateTrigger: "blur"
                                    }
                                  ],
                                  expression:
                                    "[\n              'code',\n              {\n                rules: [{ required: true, message: $t('Please input verification code') }],\n                validateTrigger: 'blur'\n              }\n            ]"
                                }
                              ],
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("Reset code")
                              }
                            },
                            [
                              _c("a-icon", {
                                style: { color: "rgba(0,0,0,.25)" },
                                attrs: { slot: "prefix", type: "mail" },
                                slot: "prefix"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "gutter-row", attrs: { span: 8 } },
                    [
                      _c("a-button", {
                        staticClass: "getCaptcha",
                        attrs: { disabled: _vm.state.smsSendBtn },
                        domProps: {
                          textContent: _vm._s(
                            (!_vm.state.smsSendBtn && _vm.$t("Resend")) ||
                              Math.floor(_vm.state.time / 60) +
                                "m " +
                                (_vm.state.time % 60) +
                                "s"
                          )
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.forgotPassword($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.sent
            ? _c(
                "a-form-item",
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "password",
                          {
                            rules: [
                              {
                                required: true,
                                min: 8,
                                message: _vm.$t(
                                  "At least 8-digit password, case sensitive."
                                )
                              }
                            ],
                            validateTrigger: ["change", "blur"]
                          }
                        ],
                        expression:
                          "[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                min: 8,\n                message: $t('At least 8-digit password, case sensitive.')\n              }\n            ],\n            validateTrigger: ['change', 'blur']\n          }\n        ]"
                      }
                    ],
                    attrs: {
                      type: "password",
                      autocomplete: "new-password",
                      placeholder: _vm.$t("New Password")
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.sent
            ? _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        block: "",
                        loading: _vm.state.smsSendBtn,
                        disabled: _vm.state.smsSendBtn
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.forgotPassword($event)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Send code")) + " ")]
                  ),
                  _c("a", { staticClass: "login", on: { click: _vm.signIn } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Sign In")) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.sent
            ? _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        block: "",
                        loading: _vm.registerBtn,
                        disabled: _vm.registerBtn
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.forgotPasswordSubmit($event)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }